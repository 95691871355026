import { AddOrUpdateDeviationRequest, BusinessEntityUserDriverLogsViewModel, BusinessEntityUserDriverLogViewObject, CompleteTaskRequest, DeliveryStepType, DistanceSummary, DriverLogRequest, MarkTaskStepsAsNotifiedRequest, StartDriverLogBreakRequest, StartDriverLogRequest, TaskProgressRequest, TasksByIdRequest, TasksForDriverLogRequest, TaskStatisticsDateEntryViewObject, TasksViewModel, TaskViewModel } from '@/api';
import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';

const api = apiInstanceFactory();

class TasksService extends ApiBase {
    public async getTaskSchedule(startDate: Date, endDate: Date): Promise<TasksViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetTaskScheduleList({
            startDate: this.formatDate(startDate),
            endDate: this.formatDate(endDate),
        }));
    }

    public async getTaskById(id: string): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetTaskByIdList({ id: id }), {
            cancellationKey: 'getTaskById',
        });
    }

    public async getTaskByIds(request: TasksByIdRequest): Promise<TasksViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetTasksByIdCreate(request), {
            cancellationKey: 'getTaskByIds',
        });
    }

    public async getTaskCountForInterval(start: Date, end: Date): Promise<TaskStatisticsDateEntryViewObject[] | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetTaskCountForIntervalList({
            start: this.formatDate(start),
            end: this.formatDate(end),
        }), {
            cancellationKey: 'getTaskCountForInterval',
        });
    }

    public async markTaskAsNotified(request: MarkTaskStepsAsNotifiedRequest): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskMarkTaskStepAsNotifiedCreate(request));
    }

    public async startTask(request: TaskProgressRequest): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskStartTaskCreate(request));
    }

    public async completeTask(request: CompleteTaskRequest): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskCompleteTaskCreate(request));
    }

    public async returnTaskToInProgress(request: TaskProgressRequest): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskReturnTaskToInProgressCreate(request));
    }

    public async addOrUpdateDeviation(request: AddOrUpdateDeviationRequest): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskAddOrUpdateDeviationCreate(request));
    }

    public async removeDeviation(taskId: string): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskRemoveDeviationDelete({ taskId }));
    }

    public async placeDeliveryInStorage(taskId: string, storageId: string): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskPlaceDeliveryInStorageCreate({
            taskId, storageId,
        }));
    }

    public async removeDeliveryFromStorage(taskId: string): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskRemoveDeliveryFromStorageCreate(taskId));
    }

    public async addOrUpdateImageInformation(taskId: string, type: DeliveryStepType, images: string[]): Promise<TaskViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskAddOrUpdateImageInformationCreate({ taskId, type }, images));
    }

    public async getDriverLogs(from: Date, to: Date): Promise<BusinessEntityUserDriverLogsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetDriverLogsList({
            from: this.formatDate(from),
            to: this.formatDate(to),
        }));
    }

    public async getActiveDriverLog(): Promise<BusinessEntityUserDriverLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetActiveDriverLogList());
    }

    public async startDriverLog(request: StartDriverLogRequest): Promise<BusinessEntityUserDriverLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskStartDriverLogCreate(request));
    }

    public async completeDriverLog(request: DriverLogRequest): Promise<BusinessEntityUserDriverLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskCompleteDriverLogCreate(request));
    }

    public async startBreak(request: StartDriverLogBreakRequest): Promise<BusinessEntityUserDriverLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskStartDriverLogBreakCreate(request));
    }

    public async stopBreak(request: DriverLogRequest): Promise<BusinessEntityUserDriverLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskStopDriverLogBreakCreate(request));
    }

    public async getTasksForDriverLogs(request: TasksForDriverLogRequest): Promise<TasksViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetTasksForDriverLogsCreate(request));
    }

    public async getDriverLogById(driverLogId: string): Promise<BusinessEntityUserDriverLogViewObject | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetDriverLogByIdList({ driverLogId }));
    }

    public async getDriversLogById(driverLogIds: string[]): Promise<BusinessEntityUserDriverLogsViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetDriverLogsByIdList(driverLogIds), {
            cancellationKey: 'getDriversLogById',
        });
    }

    public async getTimeToStorage(scheduleEntryId: string, storageId: string): Promise<DistanceSummary | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.taskGetTimeToStorageList({ scheduleEntryId, storageId }), {
            suppressErrors: true,
        });
    }
}

export default new TasksService();
