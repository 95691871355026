import { apiInstanceFactory } from '@/core/api/api.factory';
import { ApiBase } from '@/core/api/api.base';
import { SchedulesEntryViewModel } from '@/api';
import { isRunningInBrowser } from '@/infrastructure/environment';
import { LocalStorageService } from '@/core/storage/storage.service';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { userStore } from '@/store/user/user.store';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';

const api = apiInstanceFactory();

const blobApi = apiInstanceFactory({
    responseType: 'blob',
    headers: undefined,
});

class ScheduleService extends ApiBase {
    public async getSchedulesForDate(from: Date, to: Date): Promise<SchedulesEntryViewModel | undefined> {
        return this.callEndpointWithErrorHandling(() => api.api.scheduleGetSchedulesList({
            from: this.formatDate(from),
            to: this.formatDate(to),
        }));
    }
    
    public async generateTaskScheduleOverview(userId: string, scheduleFrom: Date, scheduleTo: Date): Promise<{
        fileName: string | undefined,
        file: Blob | string,
    } | undefined> {
        if (isRunningInBrowser) {
            const result = await blobApi.api.scheduleDownloadTaskScheduleOverviewPdfCreate({
                scheduleFrom: this.formatDate(scheduleFrom),
                scheduleTo: this.formatDate(scheduleTo),
            });

            const fileName = this.getContentDispositionFilename(result);
            const file = result.data as any as Blob;

            return {
                fileName,
                file,
            };
        } else {
            const fileName = `Disponerings oversigt ${userStore.user.value.fullName}_${scheduleFrom.toDateString()}-${scheduleTo.toDateString()}.pdf`;

            const downloadFileResult = await Filesystem.downloadFile({ 
                url: `https://delivery.imendo.dk/api/Schedule/DownloadTaskScheduleOverviewPdf?scheduleFrom=${this.formatDate(scheduleFrom)}&scheduleTo=${this.formatDate(scheduleTo)}`,
                headers: {
                    Authorization: `Bearer ${LocalStorageService.getItem(LocalStorageKeys.TOKEN)}`,
                },
                method: 'POST',
                responseType: 'blob',
                path: fileName,
                directory: Directory.Documents,
            });

            if (downloadFileResult.path) {
                const fileExists = await Filesystem.stat({
                    path: fileName,
                    directory: Directory.Documents,
                });

                if (fileExists) {
                    const fileOpenerOptions: FileOpenerOptions = {
                        filePath: downloadFileResult.path,
                        contentType: 'application/pdf',
                        openWithDefault: true,
                    };

                    await FileOpener.open(fileOpenerOptions);
                } else {
                    console.error('File does not exist:', downloadFileResult.path);
                }
            }
        }
    }
}

export default new ScheduleService();
